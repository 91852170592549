import React, { useEffect, useState } from "react";
import { INodeOfferProps } from "./NodeOfferProps";
import { StyleButton, StyledContainer, StyledLink } from "./NodeOfferHelpers";
import { graphql, Link, navigate } from "gatsby";
import Layout from "../../components/global/Layout/Layout";
import SEO from "../../components/global/SEO/SEO";
import MediaBanner from "../../components/common/MediaBanner/MediaBanner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Title from "../../components/global/Title/Title";
import SubTitle from "../../components/global/SubTitle/SubTitle";
import Title3 from "../../components/global/Title3/Title3";
import OfferSearchModal from "../../components/common/Offers/OfferSearchModal/OfferSearchModal";
import TemplateOffersList from "../../components/common/Offers/TemplateOffersList/TemplateOffersList";
import { IOffers } from "../../@types/IOffers";
import DeepLinking from "../../components/common/DeepLinking/DeepLinking";
import { addPageTypeInGTMDataLayer } from "../../utils/datalayers";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setSearch } from "../../redux/slices/Search/search";
import { useLocation } from "@reach/router";
//import { addKoddiPageTag } from "../../utils/KoddiTagsGTM";

const NodeOffer = (props: INodeOfferProps) => {
  const [showConditions, setShowConditions] = useState(false);
  const search = useAppSelector((state: any) => state.search);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const offer = props.data.offer;
  const rateCode = offer.field_promo_code;
  const parent = props.pageContext.parent;
  const parentType = parent && parent.type;
  let canonicalUrl = null;
  if (parent && parent?.parentOffer) {
    canonicalUrl = location?.origin + parent?.parentOffer;
  } else {
    canonicalUrl = offer.field_canonical_url ? offer.field_canonical_url : null;
  }

  const [showOfferModal, setShowOfferModal] = useState(false);

  const handleBookNow = () => {
    setShowOfferModal(true);
  };

  const handleShowConditions = () => {
    setShowConditions(!showConditions);
  };
  const otherOffers: IOffers[] = [];
  let entityOffers = [];

  if (parentType == "hotel") {
    entityOffers = props.data.otherHotelOffers.edges;
  } else if (parentType == "brand") {
    entityOffers = props.data.otherBrandOffers.edges;
  } else {
    entityOffers = props.data.otherSiteOffers.edges;
  }

  const offerBasePath = parent ? parent.path.alias : "";

  if (entityOffers.length) {
    entityOffers.forEach(({ node }: { node: IOffers }) => {
      if (offerBasePath && node.path.alias.indexOf(offerBasePath) == -1) {
        node.path.alias = `${offerBasePath}${node.path.alias}`;
      }
      otherOffers.push(node);
    });
  }
  const sonestaOnlyOffers =
    otherOffers &&
    otherOffers.filter(
      (offer: {
        relationships: {
          field_site_list?: { machine_name: string | undefined };
        };
      }) =>
        offer.relationships.field_site_list?.machine_name ==
        process.env.GATSBY_SITE_MACHINE_NAME
    );
  const brandCode = parent && parentType == "brand" ? parent.code : null;

  const allOffersLink = parent ? `${parent.path.alias}#offers` : "/offers";

  useEffect(() => {
    addPageTypeInGTMDataLayer("landing page");
  }, []);

  useEffect(() => {
    const searchPayload = {
      ...search,
      ...{
        searchString: parent?.name,
        country: parent?.address?.country_code,
        latitude: parent?.lat_lon?.lat,
        longitude: parent?.lat_lon?.lon,
      },
    };
    dispatch(setSearch(searchPayload));
  }, []);

  return (
    <Layout brand={brandCode}>
      <SEO
        title={offer.title}
        description={offer.field_meta_description}
        canonicalUrl={canonicalUrl}
        koddiTitle="Node-Offer"
      />
      <DeepLinking location={props.location} />
      {offer.relationships.banner && (
        <MediaBanner node={offer.relationships.banner}>
          <Row>
            <Col lg={5} className="text-center text-lg-left">
              <Title className="text-white">{offer.title}</Title>
              {rateCode && (
                <Button
                  data-rate={rateCode}
                  data-offername={offer.title}
                  onClick={handleBookNow}
                  className="ps-5 pe-5 mt-3"
                >
                  Book Now
                </Button>
              )}
            </Col>
          </Row>
        </MediaBanner>
      )}
      <StyledContainer fluid="sm" className="pt-5 pb-5">
        <Row>
          {offer.field_subtitle && (
            <Col lg={4}>
              <h1>{offer.field_subtitle}</h1>
            </Col>
          )}
          <Col>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: offer.description?.processed }}
            />
            {offer.conditions && (
              <StyleButton>
                <Button
                  variant="link"
                  className="btn-conditions pl-0"
                  onClick={handleShowConditions}
                >
                  READ TERMS & CONDITIONS {showConditions ? "-" : "+"}
                </Button>
                <div
                  className={`conditions ${
                    showConditions ? "d-block" : "d-none"
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: offer.conditions?.processed,
                  }}
                />
              </StyleButton>
            )}
            {rateCode && (
              <Button
                data-rate={rateCode}
                data-offername={offer.title}
                onClick={handleBookNow}
                className="ps-5 pe-5 mt-3"
                style={{ textTransform: "uppercase" }}
              >
                Book Now
              </Button>
            )}
            <Col className="mt-3">
              <StyledLink to={allOffersLink}>Back to offers</StyledLink>
            </Col>
          </Col>
        </Row>
        <OfferSearchModal
          show={showOfferModal}
          onHide={() => {
            setShowOfferModal(false);
          }}
          location={props.location}
          rate={{ code: rateCode, name: offer.title }}
        />
      </StyledContainer>
      {otherOffers.length >= 1 && (
        <Container className="bg-light pt-5 pb-5" fluid>
          <Container fluid="sm">
            <Row>
              <Col className="text-center mb-5">
                <SubTitle>Special Offers</SubTitle>
                <Title3 as="h2">
                  Other Offers from {parent ? parent.name : "Sonesta"}
                </Title3>
                <Link to={allOffersLink} className="teaser-link">
                  Explore All Offers
                </Link>
              </Col>
            </Row>
            <TemplateOffersList
              offers={sonestaOnlyOffers}
              excludeOfferId={offer.id}
            />
          </Container>
        </Container>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($parentId: String, $id: String!) {
    offer: nodeBookableOffer(id: { eq: $id }) {
      id
      title
      field_subtitle
      field_promo_code
      field_canonical_url
      field_publish_start_date_time
      field_unpublish_start_date_time
      field_meta_description
      description: body {
        processed
        value
      }
      conditions: field_terms_and_conditions {
        processed
        value
      }
      relationships {
        field_rate_code {
          code
        }
        paragraph__offers {
          parent_type
        }
        banner: field_media {
          ... on media__banner {
            id
            relationships {
              desktopImage: field_media_image {
                publicUrl
                gatsbyImage(
                  width: 1920
                  height: 400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
              mobileImage: field_media_image {
                publicUrl
                gatsbyImage(
                  width: 490
                  height: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
    otherSiteOffers: allNodeBookableOffer(
      filter: {
        id: { ne: $id }
        field_global_offer: { eq: true }
        status: { eq: true }
        relationships: { field_site_list: { machine_name: { eq: "sonesta" } } }
      }
      limit: 3
    ) {
      edges {
        node {
          id
          title
          field_subtitle
          field_promo_code
          field_canonical_url
          field_publish_start_date_time
          field_unpublish_start_date_time
          path {
            alias
          }
          description: body {
            summary
            processed
          }
          relationships {
            field_site_list {
              machine_name
            }
            field_rate_code {
              code
            }
            field_teaser_image {
              relationships {
                field_media_image {
                  publicUrl
                  gatsbyImage(
                    width: 640
                    height: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
    otherBrandOffers: allNodeBookableOffer(
      filter: {
        relationships: {
          paragraph__offers: { elemMatch: { id: { eq: $parentId } } }
        }
        id: { ne: $id }
      }
      limit: 3
    ) {
      edges {
        node {
          id
          title
          field_subtitle
          field_promo_code
          field_canonical_url
          field_publish_start_date_time
          field_unpublish_start_date_time
          path {
            alias
          }
          description: body {
            summary
            processed
          }
          relationships {
            field_site_list {
              machine_name
            }
            field_rate_code {
              code
            }
            field_teaser_image {
              relationships {
                field_media_image {
                  publicUrl
                  gatsbyImage(
                    width: 640
                    height: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
    otherHotelOffers: allNodeBookableOffer(
      filter: {
        relationships: {
          paragraph__featured_offers: { elemMatch: { id: { eq: $parentId } } }
        }
        id: { ne: $id }
      }
      limit: 3
    ) {
      edges {
        node {
          id
          title
          field_subtitle
          field_promo_code
          field_canonical_url
          field_publish_start_date_time
          field_unpublish_start_date_time
          path {
            alias
          }
          description: body {
            summary
            processed
          }
          relationships {
            field_site_list {
              machine_name
            }
            field_rate_code {
              code
            }
            field_teaser_image {
              relationships {
                field_media_image {
                  publicUrl
                  gatsbyImage(
                    width: 640
                    height: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default NodeOffer;
