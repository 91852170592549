import * as React from "react";
import { container, styledLink,styleButton } from "./NodeOffer.module.scss";
import Container from "react-bootstrap/Container";
import { Link } from "gatsby";

export const StyledContainer = (props: {children:JSX.Element | JSX.Element []}) => {
    return (<Container className={container} {...props}>{props.children}</Container>);
};

export const StyleButton = (props: {children:JSX.Element | JSX.Element []}) => {
    return (<div className={styleButton}> {props.children}</div>);
};
export const StyledLink = (props: {to: string, children:JSX.Element | string}) => {
    return (<Link to={props.to} className={styledLink}>{props.children}</Link>);
};



